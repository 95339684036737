import AbstractComponent from "./abstract-component";
import gsap from "gsap";

export default class FooterAnimation extends AbstractComponent {
	constructor(el) {
		super(el);

        this.initObserver();
	}
    initObserver() {
        let options = {
            threshold: 0.5,
        };

        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-inview');
                }
                else {
                    entry.target.classList.remove('is-inview');
                }
            })
        }, options);

        observer.observe(this.$el);
    }
	destroy() {}
}
