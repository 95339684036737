import gsap from "gsap";
import AppController from "../controller/app-controller";

let projectsZindex = 0;

export default {
    previous_time:0,
    // ---------------------------------------------
    // GLOBALS
    // ---------------------------------------------
    setUi: (way, obj) => {
        if (way === "enter") {
            let ui = obj.el.getAttribute('data-ui');
            document.documentElement.setAttribute('data-ui', ui);
        }
    },

    // ---------------------------------------------
    //   Projects
    // ---------------------------------------------
    showProject: (way, obj) => {
        if (way === "enter") {
            let index = obj.el.getAttribute('data-index');
            let prev = { index: null, zIndex: 0, cover: null, media: null, content: null };
            let next = { index: null, zIndex: 0, cover: null, media: null, content: null };

            projectsZindex = projectsZindex + 1;
            
            let $projectsNav = document.querySelector('.projects-nav');
            let $projects = document.querySelectorAll('.projectCall')
            const instance  = AppController.getInstance();
            if (document.querySelector('.template-projects')) {
                if (index == $projects.length) {
                    setTimeout(() => {
                        instance.$scroll.scrollTo($projects[0],{duration:0})
                    }, 50);
                    return
                }
            }
            // Set nav current index
            if ($projectsNav) {
                $projectsNav.setAttribute('data-index', index);
                $projectsNav.classList.remove('is-first', 'is-last');
                if (!document.querySelector('.template-projects')) {
                    if (index == 1){
                        $projectsNav.classList.add('is-first');
                    }
                    if (index == $projectsNav.getAttribute('data-max-index')){
                        $projectsNav.classList.add('is-last');
                    }      
                }
            }

            if (document.querySelector('.c-projects__covers__item.active') && document.querySelector('.c-projects__covers__item.active').getAttribute('data-index') != index) {
                prev.cover = document.querySelector('.c-projects__covers__item.active');
                prev.media = document.querySelector('.c-projects__medias__item.active');
                prev.content = document.querySelector('.c-projects__contents__item.active');
                prev.index = prev.cover.getAttribute('data-index');

                prev.cover.classList.remove('active');
                prev.media.classList.remove('active');
                prev.content.classList.remove('active');
            }
            
            next.index = index;
            next.cover = document.querySelector('.c-projects__covers__item[data-index="' + index + '"');
            next.media = document.querySelector('.c-projects__medias__item[data-index="' + index + '"');
            next.content = document.querySelector('.c-projects__contents__item[data-index="' + index + '"');

            next.cover.classList.add('active');
            next.media.classList.add('active');
            next.content.classList.add('active');

            if (prev.index){
                // gsap.set(prev.cover, { zIndex: 0 });
                // gsap.set(prev.media, { zIndex: 0 });
            }
            gsap.set(next.cover, { zIndex: projectsZindex });
            gsap.set(next.media, { zIndex: projectsZindex });

            // Cover animations
            let tlCover = gsap.timeline({
                duration: 1.2,
                ease: "expo.out",
                onComplete: () => {
                    if (prev.cover) {
                        gsap.set(prev.cover, { y: '-101%' });
                        gsap.set(prev.cover.querySelector('.inner'), { y: '101%' });
                        gsap.set(prev.cover.querySelector('img'), { scale: 1.2 });
                    }
                }
            });
            tlCover.to(next.cover, { y: 0 }, 0);
            tlCover.to(next.cover.querySelector('.inner'), { y: '0' }, 0);
            tlCover.to(next.cover.querySelector('img'), { scale: 1 }, 0);

            // Media small animations
            let tlMedia = gsap.timeline({
                duration: 1.2,
                delay: 0.2,
                ease: "expo.out",
                onComplete: () => {
                    if (prev.media) {
                        gsap.set(prev.media, { y: '101%' });
                        gsap.set(prev.media.querySelector('.inner'), { y: '-101%' });
                        gsap.set(prev.media.querySelector('img'), { scale: 1.2 });
                    }
                }
            });
            tlMedia.to(next.media, { y: 0 }, 0);
            tlMedia.to(next.media.querySelector('.inner'), { y: 0 }, 0);
            tlMedia.to(next.media.querySelector('img'), { scale: 1 }, 0);

            // Content
            if (prev.index){
                gsap.to(prev.content.querySelector('.cTitle'), { y: -40, opacity: 0, duration: 0.6, ease: "expo.out", onComplete: () => { gsap.set(prev.content.querySelector('.cTitle'), { y: 40 }); } });
                gsap.to(prev.content.querySelector('.cTitle span'), { scaleX: 0, opacity: 0, duration: 0.6, ease: "expo.out" });
                gsap.to(prev.content.querySelector('.cSubtitle'), { y: -40, opacity: 0, duration: 0.6, ease: "expo.out", delay: 0.1, onComplete: () => {
                    gsap.set(prev.content.querySelector('.cSubtitle'), { y: 40 });
                } });
                gsap.to(prev.content.querySelector('.cMoreLink'), { y: -40, opacity: 0, duration: 0.6, ease: "expo.out", delay: 0.1, onComplete: () => {
                    gsap.set(prev.content.querySelector('.cMoreLink'), { y: 40 });
                } });
                
                gsap.fromTo(next.content.querySelector('.cTitle'),
                    { y: 40, opacity: 0 },
                    { y: 0, opacity: 1, duration: 0.6, ease: "expo.out", delay: 0.5 }
                );
                gsap.fromTo(next.content.querySelector('.cTitle span'),
                    { scaleX: 0, opacity: 0 },
                    { scaleX: 1, opacity: 1, duration: 0.6, ease: "expo.out", delay: 0.5 }
                );
                gsap.fromTo(next.content.querySelector('.cSubtitle'),
                    { y: 40, opacity: 0 },
                    { y: 0, opacity: 0.75, duration: 0.6, ease: "expo.out", delay: 0.6 }
                );
                gsap.fromTo(next.content.querySelector('.cMoreLink'),
                    { y: 40, opacity: 0 },
                    { y: 0, opacity: 1, duration: 0.6, ease: "expo.out", delay: 0.6 }
                );
                
            }
        }
    },

    // ---------------------------------------------
    //   Text reveal
    // ---------------------------------------------
    textReveal: (way, obj) => {
        // if (way === "enter") {
        //     let words = obj.el.querySelectorAll('.word');
        //     gsap.to(words, {
        //         opacity: 1,
        //         stagger: 0.06,
        //         duration: 0.4,
        //         ease: "none",
        //     });
        // }
    },

    toggleTitles: (way, obj) => {
        if (way === "enter") {
            document.querySelector('.headerHome').classList.add('hide-titles');

        } else {
            document.querySelector('.headerHome').classList.remove('hide-titles');
        }
    },

    toggleReveal: (way, obj) => {
        if (way === "enter") {
            document.querySelector('.headerHome').classList.add('show-reveal');

            if (!document.querySelector('.headerHome__reveal').classList.contains('animated')) {
                document.querySelector('.headerHome__reveal').classList.add('animated');
            }
        } else {
            document.querySelector('.headerHome').classList.remove('show-reveal');
        }
    },

    jobsAppear: (way, obj) => {
        if (way === "enter") {
            let items = obj.el.closest('[data-scroll-section]').querySelectorAll('.c-slider-job__slider__item');

            items.forEach(element => {
                let options = {
                    threshold: 0.3,
                };

                let observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('is-inview');
                        }
                        // else {
                        //     entry.target.classList.remove('is-inview');
                        // }
                    })
                }, options);

                observer.observe(element);
            });
        }
    },

    activeFitItem : (way, obj) => {
        if (way === "enter") {
            let index = obj.el.getAttribute('data-index');
            let mediasWrapElt = obj.el.closest('[data-scroll-section]').querySelector('.c-fix-image-text__left');
            let activeMedia = mediasWrapElt.querySelector('.c-fix-image-text__media.visible');
            let newMedia = mediasWrapElt.querySelector('.c-fix-image-text__media[data-index="' + index + '"]');

            if (activeMedia && activeMedia.getAttribute('data-index') != index) {
                activeMedia.classList.remove('visible');
            }

            if (newMedia) {
                newMedia.classList.add('visible');
            }
        }
    }
}
