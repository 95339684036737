
import Request from "../utils/request";
import AbstractComponent from "./abstract-component";


export default class NewsList extends AbstractComponent {
	constructor(el) {
		super(el);
        this.action = 'nr_get_posts_ajax';
        this.currentLabel = "en afficher plus"
        this.initDomElements();

	}
    initDomElements(){
        this.$rowContainer = this.$el.querySelector('.c-news-list__list')
        this.$moreBtn = this.$el.querySelector('.loadMore')
        if (this.$moreBtn) {
            this.addListener();
        }


    }
    addListener(){
        this.$moreBtn.addEventListener('click',this.updateResult.bind(this))
        this.$btnLabel = this.$moreBtn.querySelector('.c-button__label')
    }
    updateResult(){
        this.currentPage = parseInt(this.$moreBtn.closest('[data-current-page]').getAttribute('data-current-page'))

        this.$btnLabel.innerHTML = "Chargement..."
        this.request = Request.AJAX({
            'url': window.JWP.ajax_url + "?action=" + this.action,
            'data': {page:this.currentPage+1},
            'success': this.onSuccess.bind( this )
        } );

    }
    onSuccess(response){
        this.$btnLabel.innerHTML = this.currentLabel
        const data = response.data
        this.currentPage++
        this.$moreBtn.closest('[data-current-page]').setAttribute('data-current-page',this.currentPage)
        this.updatePageQuery(this.currentPage)
        if (!data.has_more) {
            this.$moreBtn.style.display = 'none'
        }
        this.$rowContainer.innerHTML += data.html
        this.app.emit("scrollUpdate");
    }
    updatePageQuery(page){
        let url = new URL(window.location);
        url.searchParams.set("page", page);
        this.updateUrl(url)
    }
    updateUrl (url) {
        history.pushState(null, '', url);
    }

	destroy() {}
}
