import gsap from "gsap";

export default {
    jobs: (data) => {
        let wrap = data.el.querySelector('.c-slider-job__slider__track');
        let progress = data.progress;
        let wrapW = wrap.clientWidth;
        let maxTranslate = wrapW + (window.innerWidth * 0.5);

        if (progress >= 0 && progress <= 1) {
            gsap.set(wrap, { x: maxTranslate * progress * -1 });
        }
    },

    openDoor: (data) => {
        let door = data.el.closest('[data-scroll-section]').querySelector('.headerHome__header__backgrounds');
        let doorInner = data.el.closest('[data-scroll-section]').querySelector('.doorInner');
        let progress = data.progress;
        let maxScale = 100 / 30;
        let newScale = 1 + maxScale * progress;
        
        if (progress >= 0 && progress <= 1) {
            gsap.set(door, { scale: newScale });
            gsap.set(doorInner, { scale: 1 / newScale });
        }
    },

    readWords: (data) => {
        let words = data.el.closest('[data-scroll-section]').querySelector('[data-split-type]').querySelectorAll('.word');
        let progress = data.progress;
        let currentIndex = Math.round((words.length+5) * progress);
        words.forEach((word, index) => {
            if (index < currentIndex) {
                word.classList.add('active');
            } else {
                word.classList.remove('active');
            }
        })
    },

    cardOverlap: (data) => {
        let cardIndex = parseInt(data.el.getAttribute('data-card'));
        let totalCards = parseInt(data.el.getAttribute('data-total-cards'));

        let progress = data.progress;
        let maxScale = 0.15;
        let scaleStep = maxScale / (totalCards - 1);
        let newScale = maxScale - (scaleStep * cardIndex);

        let card = data.el.closest('[data-scroll-section]').querySelector('.c-overlap-cards__card[data-card="' + cardIndex + '"]');

        gsap.set(card, { scale: 1 - newScale * progress });
    },

    wordsSlider: (data) => {
        let progress = data.progress;
        let tracks = data.el.closest('[data-scroll-section]').querySelectorAll('.c-words__track');
        
        tracks.forEach((track) => {
            let slider = track.querySelector('.c-words__slider');
            let sliderW = slider.clientWidth;
            let maxTranslate = sliderW
            let way = (track.classList.contains('reverse')) ? 1 : -1;
            if (way == 1) {
                maxTranslate = sliderW + (window.innerWidth * 0.35);
            }
    
            if (progress >= 0 && progress <= 1) {
                gsap.set(slider, { x: maxTranslate * progress * way });
            }
        })
    },
    newsGallery: (data) => {
        let progress = data.progress;
        let tracks = data.el.closest('[data-scroll-section]').querySelectorAll('.c-news-gallery__track');
        
        tracks.forEach((track) => {
            let slider = track.querySelector('.c-news-gallery__slider');
            let sliderW = slider.clientWidth;
            let maxTranslate = sliderW * 1.2
            let way = (track.classList.contains('reverse')) ? 1 : -1;    
            if (progress >= 0 && progress <= 1) {
                gsap.set(slider, { x: maxTranslate * progress * way });
            }
        })
    },
    imageGallery: (data) => {
        let progress = data.progress;
        let tracks = data.el.closest('[data-scroll-section]').querySelectorAll('.c-image-gallery__track');
        
        tracks.forEach((track) => {
            let slider = track.querySelector('.c-image-gallery__slider');
            let sliderW = slider.clientWidth;
            let maxTranslate = sliderW
            let way = (track.classList.contains('reverse')) ? 1 : -1;
            if (way == 1) {
                maxTranslate = sliderW + (window.innerWidth * 0.3);
            }
    
            if (progress >= 0 && progress <= 1) {
                gsap.set(slider, { x: maxTranslate * progress * way });
            }
        })
    },
}
