import AbstractComponent from "./abstract-component";
import Swiper from 'swiper/bundle';
import gsap from "gsap";
import lottie from "lottie-web";

export default class FixImageText extends AbstractComponent {
	constructor(el) {
		super(el);
        this.lotties = [];
        this.initLotties();
	}
    initLotties() {
        this.$el.querySelectorAll('[data-lottie-src]').forEach((elt, index) => {
            let url = elt.getAttribute('data-lottie-src');
            elt.setAttribute('data-lottie-index', index);

            if (url) {
                let anim = lottie.loadAnimation({
                    container: elt,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: url
                });
                this.lotties.push(anim);

                anim.addEventListener('data_ready', () => {
                    this.app.emit("scrollUpdate");
                });
            }
        });
    }
	destroy() {}
}
