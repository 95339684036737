import gsap from "gsap";
import AbstractComponent from "./abstract-component";
import { CustomEase } from "gsap/all";
import SplitType from "split-type";

gsap.registerPlugin(CustomEase);
export default class SiteMenu extends AbstractComponent {
  constructor(el) {
    super(el);
    this.classname = "c-site-menu";
    this.isOpen = false;
    this.openedSubMenu = null;
    this.init();
  }
  init() {
    this.initDomElements();
    this.addEventListeners();
    this.initSplitTextMenuItem();
    this.initSplitTextSubMenuItem();
    this.initHoverLinks();
  }
  initDomElements() {
    this.$el = document.querySelector(".js-" + this.classname);
    this.$elContainer = document.querySelector(
      ".js-" + this.classname + "__container"
    );
    this.$modal = this.$el.querySelector(".js-" + this.classname + "__modal");
    this.$visual = this.$modal.querySelector(
      ".js-" + this.classname + "__modal__visual"
    );
    this.$nav = this.$modal.querySelector(
      ".js-" + this.classname + "__modal__nav"
    );
    this.$togglerButton = document.querySelector(
      ".js-" + this.classname + "__burger"
    );
    this.$togglerButtonLabel = document.querySelector(
      ".js-" + this.classname + "__burger__label"
    );
    this.$links = document.querySelectorAll(
      ".js-" + this.classname + "__modal__nav-list-item"
    );
    this.$subLinks = document.querySelectorAll(
      ".js-" + this.classname + "__modal__nav-list-item__submenu-item"
    );
    this.$submenuBack = document.querySelectorAll(
      ".js-" + this.classname + "__modal__nav__submenu-back"
    );
    this.$submenuLinks = document.querySelectorAll("[data-submenu]");
  }
  addEventListeners() {
    if (this.$togglerButton) {
      this.$togglerButton.addEventListener("click", this.toggleMenu.bind(this));
    }
    this.$submenuBack.forEach((item) => {
      item.addEventListener("click", this.closeSubMenu.bind(this));
    });
    this.$submenuLinks.forEach((item) => {
      item.addEventListener("click", this.openSubMenu.bind(this));
    });
  }
  initHoverLinks(){
    this.timerLinks = null;
    this.$links.forEach((item) => {
      const link_text = item.querySelector("a");
      link_text.addEventListener('mouseenter', () => {
        this.setVisualState();
      })
    });

    this.$subLinks.forEach((item) => {
      item.addEventListener("mouseenter", () => {
        this.setVisualStateSubmenu(item);
      });
    });
  }
  setVisualState(){
    if (this.timerLinks) return;

    let currentState = parseInt(this.$visual.getAttribute('data-state'));
    let newState = currentState == 3 ? 1 : currentState + 1;
    this.$visual.setAttribute('data-state', newState);

    this.timerLinks = setTimeout(() => {
      this.timerLinks = null
    }, 1000);
  }
  setVisualStateSubmenu(item){
    // if (this.timerLinks) return;

    let pageId = item.getAttribute('data-page-id');

    if (this.$visual.querySelector('.c-site-menu__modal__submenuShape.visible')) {
      this.$visual.querySelector('.c-site-menu__modal__submenuShape.visible').classList.remove('visible');
    }
    if (this.$visual.querySelector('.c-site-menu__modal__submenuShapes span.visible')) {
      this.$visual.querySelector('.c-site-menu__modal__submenuShapes span.visible').classList.remove('visible');
    }
    
    if (!this.$visual.querySelector('.c-site-menu__modal__submenuShape[data-page-id="' + pageId + '"]').classList.contains('visible')) {
      this.$visual.querySelector('.c-site-menu__modal__submenuShape[data-page-id="' + pageId + '"]').classList.add('visible');
    }
    if (this.$visual.querySelector('.c-site-menu__modal__submenuShape[data-page-id="' + pageId + '"]').classList.contains('isImage')) {
      this.$el.querySelector('.c-site-menu__modal__submenuShapes').classList.add('bg-gradient')
    }
    if (this.$visual.querySelector('.c-site-menu__modal span[data-page-id="' + pageId + '"]')) {
      if (!this.$visual.querySelector('.c-site-menu__modal span[data-page-id="' + pageId + '"]').classList.contains('visible')) {
        this.$visual.querySelector('.c-site-menu__modal span[data-page-id="' + pageId + '"]').classList.add('visible');
      }
    }

    // this.timerLinks = setTimeout(() => {
    //   this.timerLinks = null
    // }, 1000);
  }
  initSplitTextMenuItem() {
    this.linksTimeline = gsap.timeline({ paused: true });
    this.$links.forEach((item, index) => {
      const link_text = item.querySelector("a");
      const counter_text = item.querySelector(
        ".c-site-menu__modal__nav-list-item__counter"
      );
      const text = SplitType.create([link_text, counter_text]);
      const chars = text.chars;
      this.linksTimeline.fromTo(
        chars,
        {
          y: "100%",
        },
        {
          y: 0,
          stagger: 0.03,
          duration: 1.2,
          ease: "power4.inOut",
        },
        0
      );
    });
  }
  initSplitTextSubMenuItem() {
    this.subLinksTimeline = gsap.timeline({ paused: true});
    this.$subLinks.forEach((item) => {
      const link_text = item.querySelector("a:not(.all)");
      if (link_text) {
        const text = SplitType.create(link_text);
        const chars = text.chars;
        this.subLinksTimeline.fromTo(
          chars,
          {
            y: "100%",
          },
          {
            y: 0,
            stagger: 0.03,
            duration: 1.2,
            ease: "power4.inOut",
          },
          0
        );
      }

    });
  }
  toggleMenu() {
    this.$el.classList.toggle("nav-open");
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.openMenu();
    } else {
      // this.app.emit("enable-scroll");
      this.closeMenu();
    }
    if (this.openedSubMenu) {
      this.closeSubMenu();
    }
  }
  openMenu() {
    this.timeline = gsap.timeline({ paused: true});
    this.timeline.to(this.$modal, { pointerEvents: "all", duration: 0 });
    this.timeline.fromTo(
      [this.$nav, this.$visual],
      { y: "100vh", },
      { y: 0, ease: "0.90, 0.00, 0.10, 1.00", duration: 1.2, stagger: 0.1 }
    );
    this.timeline.play();
    this.linksTimeline.pause(0)
    setTimeout(() => {
      this.linksTimeline.play();
    }, 1000);
    this.$togglerButtonLabel.innerText = this.$togglerButtonLabel.getAttribute(
      "data-open"
    );
  }
  closeMenu() {
    this.timeline = gsap.timeline({ paused: true });
    this.timeline.to(this.$modal, { pointerEvents: "none", duration: 0 });
    this.timeline.fromTo(
      [this.$nav, this.$visual],
      { y: 0 },
      {
        y: "-100vh",
        ease: "0.90, 0.00, 0.10, 1.00",
        duration: 1.2,
        stagger: 0.1,
      }
    );
    if (this.openedSubMenu) {
      this.closeSubMenu();
      setTimeout(() => {
        this.timeline.play();
      }, 500);
    }else{
      this.timeline.play();
    }

    this.$togglerButtonLabel.innerText = this.$togglerButtonLabel.getAttribute(
      "data-close"
    );

  }
  openSubMenu(e) {
    e.stopPropagation();
    const target = e.currentTarget;
    if (!target.classList.contains('js-submenu')) {
      this.openedSubMenu = target.querySelector(".js-submenu");
      this.setVisualStateSubmenu(this.openedSubMenu.querySelector(".js-" + this.classname + "__modal__nav-list-item__submenu-item"));
      this.openedSubMenu.classList.add("open");
      this.$el.classList.add("sub-open");
      this.subLinksTimeline.pause(0)
      setTimeout(() => {
        this.subLinksTimeline.play()
      }, 500);
    }

  }
  closeSubMenu(e) {
    if (e) {
      e.stopPropagation();
    }
    if (this.$el.querySelector('.c-site-menu__modal__submenuShapes').classList.contains('bg-gradient')) {
      this.$el.querySelector('.c-site-menu__modal__submenuShapes').classList.remove('bg-gradient')
    }
    this.openedSubMenu.classList.remove("open");
    this.$el.classList.remove("sub-open");
    this.openedSubMenu = null;
  }
  handleResize() {
    // this.setheight();
  }
  destroy() {}
}
