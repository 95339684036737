import AbstractComponent from "./abstract-component";
import lottie from "lottie-web";

export default class Header extends AbstractComponent {
	constructor(el) {
		super(el);
        this.initLottie();
	}
    initLottie() {
        let elt = this.$el.querySelector('[data-lottie-src]');
        if (elt) {
            let url = elt.getAttribute('data-lottie-src');
            if (url) {
                const anim = lottie.loadAnimation({
                container: elt,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                path: url
            });
            setTimeout(() => {
                anim.play();
            }, 1800);
            } 
        }
    }
	destroy() {}
}
