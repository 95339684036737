import gsap from "gsap";
import AbstractComponent from "./abstract-component";

export default class ImageSlider extends AbstractComponent {


	constructor(el) {
		super(el);

        this.DOM = {};
        this.initDomElements();

        this.currentIndex = 1;
        this.maxIndex = this.DOM.mainItems.length;

        this.initEvents();
	}

    initDomElements() {
        this.DOM.main = this.$el.querySelector('.c-image-slider__carousel--main');
        this.DOM.second = this.$el.querySelector('.c-image-slider__carousel--second');
        this.DOM.mainItems = this.DOM.main.querySelectorAll('.c-image-slider__carousel__item');
        this.DOM.secondItems = this.DOM.second.querySelectorAll('.c-image-slider__carousel__item');

        this.DOM.navPrev = this.$el.querySelector('.c-image-slider__nav.left');
        this.DOM.navNext = this.$el.querySelector('.c-image-slider__nav.right');
    }

    initEvents () {
        this.DOM.navPrev.addEventListener('click', (e) => {
            this.navigate(-1);
        });
        this.DOM.navNext.addEventListener('click', (e) => {
            this.navigate(1);
        });
    }

    navigate (way = 1) {
        let oldIndex = this.currentIndex;
        let newIndex = null;

        if (way == 1) {
            newIndex = (oldIndex < this.maxIndex) ? oldIndex + 1 : 1;
        } else {
            newIndex = (oldIndex == 1) ? this.maxIndex : oldIndex - 1;
        }

        this.currentIndex = newIndex;
        this.animate(this.DOM.main, oldIndex, newIndex, way);
        this.animate(this.DOM.second, oldIndex, newIndex, way);
    }

    animate (carousel, oldIndex, newIndex, way) {
        let oldItem = this.getItem(carousel, oldIndex);
        let newItem = this.getItem(carousel, newIndex);
        
        gsap.set(oldItem, { zIndex: 0 });
        gsap.set(newItem, { zIndex: 1 });

        let tlCover = gsap.timeline({
            onComplete: () => {
                if (oldItem) {
                    gsap.set(oldItem, { x: '101%' });
                    gsap.set(oldItem.querySelector('.inner'), { x: '-101%' });
                    gsap.set(oldItem.querySelector('img'), { scale: 1.4 });
                }
            }
        });
        tlCover.fromTo(newItem,
            { x: (way * 101) + '%' },
            { x: 0, duration: 1.6, ease: "expo.inOut" },
        0);
        tlCover.fromTo(newItem.querySelector('.inner'),
            { x: (way * -101) + '%' },
            { x: '0', duration: 1.6, ease: "expo.inOut" },
        0);
        tlCover.fromTo(newItem.querySelector('img'),
            { scale: 1.4 },
            { scale: 1, duration: 1.6, ease: "expo.inOut" },
        0);


    }

    getItem (parent, index) {
        return parent.querySelector('.c-image-slider__carousel__item[data-index="' + index + '"]');
    }

	destroy() {}
}
