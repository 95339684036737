import AbstractComponent from "./abstract-component";
import lottie from "lottie-web";

export default class Form extends AbstractComponent {
	constructor(el) {
		super(el);
		this.initDomElements()
		this.objects = {
			"Recrutement - candidature spontanée":"recrutement",
			"Affaires":"affaires",
			"Autres":"autres",
		}
		this.addListeners()
		this.setSelectsClass();

	}
	initDomElements(){
		this.$inputs = this.$el.querySelectorAll('input.wpcf7-form-control,textarea.wpcf7-form-control')
		this.$file = this.$el.querySelector('[data-file-input]')
		this.$objectField = this.$el.querySelector('select')
		this.$selects = this.$el.querySelectorAll('select')
		this.$fileInput = this.$file.querySelector('input')
		this.$fileLabel = this.$file.querySelector('.text')
		this.$fileImg = this.$file.querySelector('img')
		this.$deleteFile = this.$file.querySelector('.delete-file')
	}
	addListeners(){
		this.$fileInput.addEventListener('change',this.handleUpload.bind(this))
		this.$deleteFile.addEventListener('click',this.handleDelete.bind(this))
		this.$inputs.forEach(input => {
			input.addEventListener('focus',this.handleFocus.bind(this))
			input.addEventListener('blur',this.handleBlur.bind(this))
		});
		this.$objectField.addEventListener('change',()=>{
			console.log(this.$objectField.value);
			if (this.$objectField.value) {
				window.location.hash = this.objects[this.$objectField.value]
			}else{
				window.location.hash = ""
			}


		})
		window.addEventListener("hashchange", this.setValue.bind(this));
		this.setValue()
	}
	setValue(){
		const value = Object.keys(this.objects).find(key => this.objects[key] === window.location.hash.replace('#',''));
		if (value) {
			this.$objectField.value = value 
			this.$objectField.dispatchEvent(new Event('change'));	
		}else{
			this.$objectField.value = "" 
		}
	
	}
	setSelectsClass(){
		this.$selects.forEach(select=> select.closest('p').classList.add('is-select'))
	}
	handleFocus(e){
		const target = e.target
		const container = target.closest('p')
		container.classList.add('focus')
	}
	handleBlur(e){
		const target = e.target
		if (!target.value) {
			const container = target.closest('p')
			container.classList.remove('focus')	
		}
	}
	handleUpload(e){
		const files = e.target.files;
		const fileName = files[0].name.length > 30 ? files[0].name.substring(0,30) + "...": files[0].name;
		this.labelContent = this.$fileLabel.innerHTML;
		this.$fileLabel.innerHTML = fileName;
		this.$fileImg.style.display = 'block'
		this.$deleteFile.style.display = 'block'
		this.$file.classList.add('has-file')
	}
	handleDelete(){
		this.$fileLabel.innerHTML = this.labelContent;
		this.$fileImg.style.display = 'none'
		this.$deleteFile.style.display = 'none'
		this.$fileInput.value = '';
		this.$file.classList.remove('has-file')
	}
	destroy() {}
}
