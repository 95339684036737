import EventEmitter from "eventemitter3";
import SiteMenu from "../components/site-menu";
import LocomotiveScroll from "../components/locomotive-scroll";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import components from "../components/_map";
import { debounce } from "lodash";
import PageLoader from "../components/page-loader";
import Utils from "../utils/utils";
import GridDebug from '../utils/GridDebug';
import AnimationController from "./animation-controller";

export default class AppController extends EventEmitter {
	constructor() {
		super();

		// Handling singleton creation
		if (!AppController.singleton) {
			// Storing the singleton instance
			AppController.singleton = this;
			this.components = [];
			this.currentController = document.body.getAttribute('data-controller');
			this.components_map = components;
			// Returning the singleton
			this.init();
			return AppController.singleton;
		}
	}
	init() {
		this.initAppEvents();
		this.initDomElements();
		this.instantiateComponents();

		this.initViewportVhVar();
		this.initDetect();
		this.$menu = new SiteMenu();
		this.$scroll = new LocomotiveScroll();
		this.$animation_controller = new AnimationController();
	}
	initDomElements() {
		this.$site_container = document.querySelector('.site-container');
		this.$navbar = document.querySelector(".c-site-menu__container");
		this.$footer = document.querySelector('.c-site-footer');
	}
	initAppEvents() {
		window.addEventListener("resize",()=>{
			debounce((event) => {
				this.emit("resize", event);
			}, 16)
		})
		window.addEventListener("scroll", (event) => {
			this.emit("scroll", event);
		})
		window.onhashchange = () => {
			this.emit("hash-change", window.location.href);
		};

		// Scroll events
		this.on("disable-scroll", () => {
			disableBodyScroll(this.$site_container);
			if (this.$scroll) {
				this.$scroll.scroll.stop()
			}
		});
		this.on("enable-scroll", () => {
			enableBodyScroll(this.$site_container);
			if (this.$scroll) {
				this.$scroll.enable()
			}
		});
		this.on("scrollTo", (elt) => {
			this.$scroll.scrollTo(elt);
		});
		this.on("scrollUpdate", () => {
			this.$scroll.update();
		});
		
		// Navbar events
		this.on("hide-navbar", () => {
			this.$navbar.classList.add('hidden');
		});
		this.on("show-navbar", () => {
			this.$navbar.classList.remove('hidden');
		});
	}
	instantiateComponents() {
		const components = document.body.querySelectorAll("[data-component]");
		for (let i = 0; i < components.length; i++) {
			const component = components[i];
			const name = component.getAttribute("data-component");
			if (this.components_map.hasOwnProperty(name)) {
				const componentClass = this.components_map[name];
				if (componentClass) {
					this.components.push(new componentClass.default(component));
				}
			}
		}
	}

    initViewportVhVar() {
        this.updateViewportVhVar();
		if (Utils.deviceType() == 'desktop') {
			window.addEventListener("resize",() => {
				this.updateViewportVhVar()
			});
		}
    }
    updateViewportVhVar() {
        let vh = window.innerHeight;
        document.documentElement.style.setProperty( '--vh', `${ vh/100 }px` );
    }

	initDetect() {
		window.addEventListener("resize",()=>{
			this.detectDevice();
		});
		this.detectDevice();
	}
	detectDevice = () => {
		let landscape = window.matchMedia("(orientation: landscape)");
		document.documentElement.setAttribute('data-device-notice', Utils.deviceType() == 'mobile' && landscape.matches && window.innerWidth < 1000);
	}

	static getInstance() {
		return AppController.singleton;
	}
}
