import gsap from "gsap";
import AbstractComponent from "./abstract-component";
import lottie from "lottie-web";

export default class PageLoader extends AbstractComponent {
	constructor(el) {
		super(el);
		this.timelineOpening = null;
		this.shouldRun = true;
		this.init();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
		let loader = document.querySelector('.loading')
		const lastLoader = parseInt(localStorage.getItem('norea-loader'), 10),
        nowDate = Date.now();
        /* Every hour */
        if (!loader || (lastLoader && nowDate < lastLoader + 1000 * 60 * 60)) {
				this.runOpenAnimation();
		}else{
			this.timeline = gsap.timeline({paused:true})
			this.timeline.fromTo(this.$el,{y:"0"},{y:"-100%",duration:0,ease: "expo.out"})
			this.timeline.play()
		}
		
	}
	initDomElements() {
		this.$pageLinks = document.querySelectorAll("a");
		this.$lottie = this.$el.querySelector("[data-lottie-src]");
	}
	addEventListeners() {
		this.$pageLinks.forEach((link) => {
			link.addEventListener("click", this.handlePageChange.bind(this));
		});

		// Preventing being stuck on the loader when navigating with history buttons
		window.onpageshow = function(event) {
			if (event.persisted) {
				window.location.reload();
			}
		};
		
	}
	playLottie(reversed = false){
		if (!this.anim) {
			this.anim = lottie.loadAnimation({
				container: this.$lottie,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				path: this.$lottie.getAttribute("data-lottie-src")
			});
		}

		if (reversed) {
			this.anim.goToAndStop(40,true)
			this.anim.addEventListener('data_ready',()=>{
				this.anim.play()
				this.$el.querySelector('img').style.opacity = "0"
				setTimeout(() => {
					this.timeline.play()

					this.$el.classList.add('close')
				}, 1500);
			   })
		}else{
			this.anim.goToAndPlay(0,true)
			this.anim.addEventListener("enterFrame",(animation)=> {
				if (animation.currentTime >= 40) {
					this.anim.pause();
				}
		   });
		}

	}
	runOpenAnimation() {
		this.timeline = gsap.timeline({paused:true})
		this.timeline.fromTo(this.$el,{y:"0"},{y:"-100%",duration:1,ease: "expo.out"})
		this.playLottie(true);


		
	}
	runCloseAnimation() {
		this.timeline = gsap.timeline({paused:true,onComplete:()=> this.navigatePage()})
		this.timeline.fromTo(this.$el,{opacity:0,pointerEvents:"none"},{opacity:1,pointerEvents:"all",duration:0})
		this.timeline.fromTo(this.$el,{y:"100%"},{y:0,duration:1,ease: "expo.out"})
		this.timeline.play()
		this.playLottie();
	}
	handlePageChange(e) {
		const link = e.currentTarget;
		if (link.getAttribute("target") != "_blank" && !link.hash && !link.href.endsWith('#') && !link.href.startsWith('mailto') && !link.hasAttribute('download')) {
			this.nextPage = link.href;
			e.preventDefault();
			this.runCloseAnimation();
		}
	}
	navigatePage() {
		window.location.href = this.nextPage
	}
	destroy() {}
}