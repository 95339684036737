import AbstractComponent from "./abstract-component";
import Swiper from 'swiper/bundle';

export default class VideoGallery extends AbstractComponent {
	constructor(el) {
		super(el);
        this.initDomElements();
        this.addListeners();
        this.initSwiper();
	}
    initDomElements(){
        this.swiperElt = this.$el.querySelector('.swiper');
        this.swiperNavPrev = this.$el.querySelector('.videosSwiper__nav.left');
        this.swiperNavNext = this.$el.querySelector('.videosSwiper__nav.right');
        this.$videos = this.$el.querySelectorAll('[data-yt-id]')
    }
    addListeners(){
        this.$videos.forEach(video => {
            video.addEventListener('click',this.initVideo.bind(this))
        });
    }
    initVideo(e){
        console.log(e.currentTarget);
        this.app.emit("play-video", e.currentTarget.getAttribute('data-yt-id'));
    }
	initSwiper() {
        this.swiper = new Swiper(this.swiperElt, {
            init: true,
            slidesPerView: 1,
            spaceBetween: 15,
            loop: true,
            navigation: {
                nextEl: this.swiperNavNext,
                prevEl: this.swiperNavPrev,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1190: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        });
    }
	destroy() {}
}
