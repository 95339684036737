import AbstractComponent from "./abstract-component";
import lottie from "lottie-web";

export default class P404 extends AbstractComponent {
	constructor(el) {
		super(el);

        this.initLottie();
	}
    initLottie() {
        let elt = this.$el.querySelector('[data-lottie-src]');
        if (elt) {
            let url = elt.getAttribute('data-lottie-src');
            let anim = lottie.loadAnimation({
                container: elt,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: url
            });
        }
    }
	destroy() {}
}
