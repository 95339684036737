import LocomotiveScroll from 'locomotive-scroll';
import AbstractComponent from "./abstract-component";
import lcsCallbacks from './lcs-callbacks';
import lcsProgress from './lcs-progress';

export default class LCS extends AbstractComponent {
	constructor(el) {
		super(el);
		this.init();
	}
	init() {
		this.createScroll();
	}
    createScroll() {
        this.scroll = new LocomotiveScroll({
            el: document.querySelector('.site-container[data-scroll-container]'),
            smooth: document.querySelector('.template-form') ?false:true,
            getSpeed: true,
            getDirection: true,
            gestureDirection: 'vertical',
            direction: 'vertical',
            tablet: {
                smooth: document.querySelector('.template-form') ?false:true,
                gestureDirection: 'vertical',
                direction: 'vertical',
                breakpoint: 1190
            },
            smartphone: {
                smooth: false
            }
        });

        if (this.app.currentController == 'home') {
            setTimeout(() => {
                let loader = document.querySelector('.loading')
                if (loader) {
                    if (!loader.classList.contains("hidden")) {
                        this.scroll.stop();
                        this.app.emit("disable-scroll");   
                    }
                }
            }, 100);

        }

        this.scroll.on('call', (value, way, obj) => {
            let calls = (typeof value === 'string') ? [value] : value;
            this.scrollAnimations(calls, way, obj);
        });

        this.scroll.on('scroll', (args) => {
            this.onScroll(args);
        });
    }
	scrollAnimations(calls, way, obj) {
        calls.forEach(call => {
            if (lcsCallbacks[call]) {
                lcsCallbacks[call](way, obj);
            }
        });
	}
    onScroll(args) {
        if (args.direction == 'down' && args.scroll.y > window.innerHeight * 0.5) {
            this.app.emit("hide-navbar");
        } else {
            if (args.scroll.y > 0) {
                this.app.emit("show-navbar");
            }
        }

        for (const [key, data] of Object.entries(args.currentElements)) {
            if (lcsProgress[key]) {
                lcsProgress[key](data);
            } else {
                let attr = data.el.getAttribute('data-scroll-progress');
                if (attr && lcsProgress[attr]){
                    lcsProgress[attr](data);
                }
            }
        }
    }
    disable() {
        this.scroll.stop();
    }
    enable() {
        this.scroll.start();
    }
    scrollTo(elt, offset = 0) {
        this.scroll.scrollTo(elt, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00], offset: offset });
    }
    scrollTop (force = false) {
        if (force) {
            this.scroll.scrollTo(0, { duration: 0, disableLerp: true });
        } else {
            this.scroll.scrollTo(0, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00] });
        }
    }
    update() {
        this.scroll.update();
    }
	destroy() {}
}
