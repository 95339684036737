import AbstractComponent from "./abstract-component";
import gsap from "gsap";
import SplitType from "split-type";
import VanillaTilt from "vanilla-tilt";
import lottie from "lottie-web";

export default class HeaderHome extends AbstractComponent {
	constructor(el) {
		super(el);

        this.$loading = document.querySelector(".loading");

        this.$header = this.$el;
        this.$header_intro = this.$header.querySelector(".headerHome__intro");
        this.$header_titlesWrap = this.$header.querySelector(".headerHome__header__titles");
        this.$header_titles = this.$header.querySelectorAll(".headerHome__header__title");
        this.$scroll_indicator = this.$header.querySelector(".c-scroll-indicator");
        this.$header_reveal = this.$header.querySelector(".headerHome__reveal");

        this.initLoading();

        if (this.$header_intro) {
            this.initIntro();
        }

        this.initTitles();
        this.initShapesTilt();

        if (this.$header_reveal) {
            this.initReveal();
        }
	}
    initLoading() {
        const lastLoader = parseInt(localStorage.getItem('norea-loader'), 10),
        nowDate = Date.now();
        /* Mark loader */
        localStorage.setItem('norea-loader', nowDate);

        if (this.$loading) {
            /* Every hour */
            if (lastLoader && nowDate < lastLoader + 1000 * 60 * 60) {
                document.body.setAttribute('data-home-loader-disabled',1);
                this.hideLoading()
                return;
            }
            this.app.emit("hide-navbar");
            this.$scroll_indicator.classList.add('hidden');
            this.$loading.style.display = "flex"
            let elt = this.$loading.querySelector('[data-lottie-src]');
            let url = elt.getAttribute('data-lottie-src');
            if (url) {
                let anim = lottie.loadAnimation({
                    container: elt,
                    renderer: 'svg',
                    loop: false,
                    autoplay: true,
                    path: url
                });

                anim.addEventListener('complete', this.hideLoading.bind(this));
            }
        }
    }
    hideLoading() {
        this.$loading.classList.add('hidden');
        if (this.$header_intro) {
            this.showIntro(0.5);
        } else {
            this.$scroll_indicator.classList.remove('hidden');
            this.playTitles(200);
            this.app.emit("show-navbar");
            this.app.emit("enable-scroll");
        }
    }
    initIntro() {
        setTimeout(() => {
            new SplitType(this.$header_intro.querySelector(".introContent"), { types: "lines" });
            gsap.set(this.$header_intro.querySelectorAll('.introContent'), { opacity: 1 });
    
            this.$header_intro.querySelector('.c-button').addEventListener('click', this.hideIntro.bind(this));
        }, 500);
    }
    showIntro(delay = 0) {
        gsap.fromTo(this.$header_intro.querySelector('.st3'),
            { y: 75, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, ease: "expo.out", delay: delay }
        );
        gsap.fromTo(this.$header_intro.querySelectorAll('.line'),
            { y: 75, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, ease: "expo.out", stagger: 0.075, delay: delay + 0.5 }
        );
        gsap.fromTo(this.$header_intro.querySelector('.c-button'),
            { y: 20, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, ease: "expo.out", delay: delay + 1.5 }
        );
    }
    hideIntro() {
        gsap.to(this.$header_intro.querySelector('.introInner'),
            { scale: 0.9, opacity: 0, duration: 0.8, ease: "expo.out" }
        );
        this.$header_intro.classList.add('hidden');
        this.$scroll_indicator.classList.remove('hidden');
        this.playTitles(200);
        this.app.emit("show-navbar");
        this.app.emit("enable-scroll");
    }
    initTitles() {
        this.timelines = [];
        this.$header_titles.forEach((item, index) => {
            this.timelines[index] = gsap.timeline({ paused: true });

            const text = SplitType.create(item);
            const words = text.words;

            this.timelines[index].fromTo(
                words,
                {
                    y: '100%',
                    opacity: 0,
                    scale: 0.9
                },
                {
                    y: 0,
                    opacity: 1,
                    scale: 1,
                    stagger: 0.1,
                    duration: 1.2,
                    ease: "power4.inOut",
                }
            );

            this.timelines[index].to(words, {
                y: -140,
                opacity: 0,
                scale: 0.7,
                delay: 3,
                stagger: 0.03,
                duration: 0.8,
                ease: "power4.inOut",
                onStart: () => {
                    setTimeout(() => {
                        this.timelines[
                            index + 1 < this.$header_titles.length ? index + 1 : 0
                        ].play(0);

                        gsap.to(
                            this.$header.querySelector('.headerHome__header__background.door'),
                            {
                                scale: 1.02,
                                repeat: 1,
                                duration: 0.4,
                                yoyo: true,
                                ease: "circ.inOut",
                            }
                        );

                    }, 0.8);
                }
            });

            // if (index == 0) {
                this.$header_titlesWrap.classList.add('ready');
                // this.timelines[0].play();
            // }
        });
    }
    playTitles(delay = 0) {
        setTimeout(() => {
            this.timelines[0].play();
        }, delay);
    }
    initShapesTilt() {
        if (this.$header && this.$header.querySelectorAll('[data-tilt]')) {
            VanillaTilt.init(this.$header.querySelectorAll('[data-tilt]'));
        }
    }

    initReveal() {
        let splitElt = this.$header_reveal.querySelector("[data-split-type]");
        let splitTypes = splitElt.getAttribute('data-split-type') ? splitElt.getAttribute('data-split-type') : 'words';
        new SplitType(splitElt, { types: splitTypes });
    }
	destroy() {}
}
