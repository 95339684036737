export default {
    'scroll-indicator': require( './scroll-indicator.js' ),
    'projects-nav': require( './projects-nav.js' ),
    'commitments': require( './commitments.js' ),
    'form': require( './form.js' ),
    'video-popin': require( './video-popin.js' ),
    'image-slider': require( './image-slider.js' ),
    'page-loader': require( './page-loader.js' ),
    'header-home': require( './header-home.js' ),
    'text-reveal': require( './text-reveal.js' ),
    'fix-image-text': require( './fix-image-text.js' ),
    'dropdown': require( './dropdown.js' ),
    'overlap-cards': require( './overlap-cards.js' ),
    'dropdown': require( './dropdown.js' ),
    'video-gallery': require( './video-gallery.js' ),
    'p404': require( './p404.js' ),
    'header': require( './header.js' ),
    'footer-animation': require( './footer-animation.js' ),
    'news-list':require( './news-list.js' ),
}
