import AbstractComponent from "./abstract-component";
import Swiper from 'swiper/bundle';
import gsap from "gsap";
import lottie from "lottie-web";

export default class Commitments extends AbstractComponent {
	constructor(el) {
		super(el);

        this.swiperElt = this.$el.querySelector('.swiper');
        this.swiperNavPrev = this.$el.querySelector('.c-commitments__swiper__nav.left');
        this.swiperNavNext = this.$el.querySelector('.c-commitments__swiper__nav.right');

        this.lotties = [];

        this.initLotties();
        this.initSwiper();
	}
	initSwiper() {
        this.swiper = new Swiper(this.swiperElt, {
            init: false,
            effect: 'fade',
            allowTouchMove: false,
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: this.swiperNavNext,
                prevEl: this.swiperNavPrev,
            }
        });
        this.swiper.on('afterInit', this.afterInit.bind(this));
        this.swiper.on('slideChange', this.onChange.bind(this));
        this.swiper.init();
    }
    onChange(data) {
        this.hideSlide(data.slides[data.previousIndex]);
        this.showSlide(data.slides[data.activeIndex], 0.4);
    }
    afterInit(data) {
        this.showSlide(data.slides[data.activeIndex]);
    }
    showSlide(slide, delay = 0) {
        gsap.fromTo(slide.querySelector('.c-commitments__content__title'),
            { y: 40, opacity: 0 },
            { y: 0, opacity: 1, duration: 0.6, ease: "expo.out", delay: delay }
        );
        gsap.fromTo(slide.querySelector('.c-commitments__content__description'),
            { y: 40, opacity: 0 },
            { y: 0, opacity: 1, duration: 0.6, ease: "expo.out", delay: delay + 0.1 }
        );
        gsap.fromTo(slide.querySelector('.c-button'),
            { y: 40, opacity: 0 },
            { y: 0, opacity: 1, duration: 0.6, ease: "expo.out", delay: delay + 0.2 }
        );

        // Play Lottie
        this.lotties[parseInt(slide.querySelector('.c-commitments__content__lottie').getAttribute('data-lottie-index'))].play();
    }
    hideSlide(slide, delay = 0) {
        gsap.fromTo(slide.querySelector('.c-commitments__content__title'),
            { y: 0, opacity: 1 },
            { y: -40, opacity: 0, duration: 0.4, ease: "expo.out", delay: delay }
        );
        gsap.fromTo(slide.querySelector('.c-commitments__content__description'),
            { y: 0, opacity: 1 },
            { y: -40, opacity: 0, duration: 0.4, ease: "expo.out", delay: delay + 0.1 }
        );
        gsap.fromTo(slide.querySelector('.c-button'),
            { y: 0, opacity: 1 },
            { y: -40, opacity: 0, duration: 0.4, ease: "expo.out", delay: delay + 0.2 }
        );

        // Pause Lottie
        this.lotties[parseInt(slide.querySelector('.c-commitments__content__lottie').getAttribute('data-lottie-index'))].goToAndStop(0);
    }
    initLotties() {
        this.$el.querySelectorAll('[data-lottie-src]').forEach((elt, index) => {
            let url = elt.getAttribute('data-lottie-src');
            elt.setAttribute('data-lottie-index', index);
            if (url) {
                let anim = lottie.loadAnimation({
                    container: elt,
                    renderer: 'svg',
                    loop: true,
                    autoplay: false,
                    path: url,
                });
                this.lotties.push(anim);
                anim.addEventListener('data_ready', () => {
                    this.app.emit("scrollUpdate");
                });
            }
        });
    }
	destroy() {}
}
