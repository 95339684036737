export default class AnimationController {
    constructor() {
        // Handling singleton creation
        if (!AnimationController.singleton) {
            // Storing the singleton instance
            AnimationController.singleton = this;
            // Returning the singleton

            return AnimationController.singleton;
        }
    }
    static getInstance() {
        return AnimationController.singleton;
    }
}
