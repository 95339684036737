import AbstractComponent from "./abstract-component";
import Utils from "../utils/utils";

export default class ScrollIndicator extends AbstractComponent {
	constructor(el) {
		super(el);
		this.init();
	}
	init() {
		this.addEventListeners();
	}
	addEventListeners() {
		this.$el.addEventListener("click", this.scrollDown.bind(this));
	}
    scrollDown(){
		let header = this.$el.closest('[data-scroll-section]');
		let nextSection = Utils.getNextSibling(header, '[data-scroll-section]');
		let target = this.$el.getAttribute('data-target') && this.$el.getAttribute('data-target') != '' ? this.$el.getAttribute('data-target') : nextSection;
		this.app.emit("scrollTo", target);
    }
	destroy() {}
}
