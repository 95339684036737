import AbstractComponent from "./abstract-component";

export default class ProjectsNav extends AbstractComponent {
	constructor(el) {
		super(el);
		this.prev = this.$el.querySelector('.projects-nav__button--prev');
		this.next = this.$el.querySelector('.projects-nav__button--next');
		this.addEventListeners();
	}
	addEventListeners() {
		this.prev.addEventListener("click", this.goToPrev.bind(this));
		this.next.addEventListener("click", this.goToNext.bind(this));
	}
	goToPrev() {
		if (this.getIndex() > 1) {
			this.app.emit("scrollTo", document.querySelector('.projectCall[data-index="' + (this.getIndex() - 1) + '"]'));
		}else if(document.querySelector('.template-projects')){
			this.app.emit("scrollTo", document.querySelector('.projectCall[data-index="' + this.getMaxIndex() + '"]'));	
		}
	}
	goToNext() {
		if (this.getIndex() < this.getMaxIndex()) {
			this.app.emit("scrollTo", document.querySelector('.projectCall[data-index="' + (this.getIndex() + 1) + '"]'));
		}else if(document.querySelector('.template-projects')){
			this.app.emit("scrollTo", document.querySelector('.projectCall[data-index="' + 1 + '"]'));	
		}
	}
	getIndex() {
		return parseInt(this.$el.getAttribute('data-index'));
	}
	getMaxIndex() {
		return parseInt(this.$el.getAttribute('data-max-index'));
	}
	destroy() {}
}
