import AbstractComponent from "./abstract-component";
import Player from '@vimeo/player';

export default class VideoPopin extends AbstractComponent {
	constructor(el) {
		super(el);
        this.classname = "c-video-popin"
		this.playerId = "video-player";
		this.isOpen = false;
		this.init();
		this.updateMediaVideoWidth();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
	}
	initDomElements() {
		this.$video = this.$el.querySelector(
			".js-" + this.classname + "__video__player"
		);
		this.$cross = this.$el.querySelector(".js-" + this.classname + "__cross");
		this.$video_container = this.$el.querySelector(
			".js-" + this.classname + "__video"
		);
	}
	addEventListeners() {
		this.app.on("play-video", this.playVideo.bind(this));
		this.app.on("resize", this.updateMediaVideoWidth.bind(this));
		this.$cross.addEventListener("click", this.closeVideo.bind(this));
	}
	async playVideo(e) {
		this.app.emit("disable-scroll");
		this.$el.classList.add("open");
		// this.player = YouTubePlayer(this.$video, {
		// 	videoId: e,
		// 	host: "https://www.youtube-nocookie.com",
		// 	playerVars: {
		// 		autoplay: 0,
		// 		color: "white",
		// 		playsinline: 0,
		// 	},
		// });
        const options = {
            id:  e,
        };
        this.player = new Player(this.$video, options);
		await this.player.play();
	}
	closeVideo() {
		this.app.emit("enable-scroll");
		this.$el.classList.remove("open");
		this.player.pause();
		this.player.destroy();
	}
	updateMediaVideoWidth() {
		if (this.$video_container) {
			// Page width and height & video ratio
			const mediaBoundingClientRect = this.$el.getBoundingClientRect();
			const mediaWidth = mediaBoundingClientRect.width;
			const mediaHeight = mediaBoundingClientRect.height;
			const mediaVideoRatio = 16 / 9;

			// Computing the video container's width & height
			let videoContainerWidth = mediaWidth;
			let videoContainerHeight = Math.floor(
				videoContainerWidth / mediaVideoRatio
			);

			// Checking if the player is too high
			if (videoContainerHeight > mediaHeight) {
				videoContainerHeight = mediaHeight;
				videoContainerWidth = Math.floor(
					videoContainerHeight * mediaVideoRatio
				);
			}
			// Updating the video container
            this.$video_container.style.width = videoContainerWidth + "px"

		}
	}

	destroy() {}
}
