import AbstractComponent from "./abstract-component";
import SplitType from "split-type";

export default class TextReveal extends AbstractComponent {
	constructor(el) {
		super(el);

        let splitElt = this.$el.querySelector("[data-split-type]");
		if (splitElt) {
			let splitTypes = splitElt.getAttribute('data-split-type') ? splitElt.getAttribute('data-split-type') : 'words';
			new SplitType(splitElt, { types: splitTypes });
		}

	}
	destroy() {}
}
