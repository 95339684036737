import AbstractComponent from "./abstract-component";
import lottie from "lottie-web";

export default class Dropdown extends AbstractComponent {
	constructor(el) {
		super(el);

        this.lotties = [];

        this.initLotties();
        this.initEvents();

	}

    initEvents() {
        this.$el.querySelectorAll('.c-dropdown__dropdown__header').forEach((elt) => {
            elt.addEventListener('click', (e) => {
                this.openDropdown(elt);
            });
        });
    }

    openDropdown(elt) {
        let index = elt.getAttribute('data-index');
        let prevDropdown, nextDropdown;
        let prevMedia, nextMedia;
        if (!elt.closest('.c-dropdown__dropdown').classList.contains('open')) {
            
            prevDropdown = this.$el.querySelector('.c-dropdown__dropdown.open');
            prevDropdown.classList.remove('open');

            prevMedia = this.$el.querySelector('.c-dropdown__media.visible');
            prevMedia.classList.remove('visible');

            if (prevMedia.getAttribute('data-type') == 'lottie') {
                this.lotties[parseInt(prevMedia.querySelector('.c-dropdown__media__lottie').getAttribute('data-lottie-index'))].goToAndStop(0);
            }

            nextDropdown = elt.closest('.c-dropdown__dropdown');
            nextMedia = this.$el.querySelector('.c-dropdown__media[data-index="' + index + '"]');

            setTimeout(() => {
                nextDropdown.classList.add('open');
                nextMedia.classList.add('visible');

                if (nextMedia.getAttribute('data-type') == 'lottie') {
                    this.lotties[parseInt(nextMedia.querySelector('.c-dropdown__media__lottie').getAttribute('data-lottie-index'))].play();
                }
                
                setTimeout(() => {
                    this.app.emit("scrollUpdate");
                }, 900);
            }, 600);

        }
    }

    initLotties() {
        this.$el.querySelectorAll('[data-lottie-src]').forEach((elt, index) => {
            let url = elt.getAttribute('data-lottie-src');
            elt.setAttribute('data-lottie-index', index);
            let autoplay = elt.hasAttribute('data-lottie-autoplay');
            if (url) {
                let anim = lottie.loadAnimation({
                    container: elt,
                    renderer: 'svg',
                    loop: true,
                    autoplay: autoplay,
                    path: url,
                });
                this.lotties.push(anim);

                anim.addEventListener('data_ready', () => {
                    this.app.emit("scrollUpdate");
                });

                if (elt.closest('.c-dropdown__media') && elt.closest('.c-dropdown__media').classList.contains('visible')) {
                    anim.play();
                }
            }
        });
    }

	destroy() {}
}
  