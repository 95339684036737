import AbstractComponent from "./abstract-component";
import lottie from "lottie-web";
import Utils from "../utils/utils";

export default class OverlapCards extends AbstractComponent {
	constructor(el) {
		super(el);

        this.$cards = this.$el.querySelectorAll('.c-overlap-cards__card');
        this.nbCards = this.$cards.length;
        this.lotties = [];

        this.initNav();
        this.initLotties();

        this.sameHeight();
        window.addEventListener('resize', Utils.debounce(() => { this.sameHeight() }), { passive: true });
	}

    sameHeight() {
        let heights = [];

        for (var i = 0; i < this.nbCards; i++) {
            this.$cards[i].setAttribute('style', '');
        }
    
        for (var i = 0; i < this.nbCards; i++) {
            heights.push(this.$cards[i].offsetHeight);
        }

        let tallest = Math.max(...heights);
    
        for (var i = 0; i < this.nbCards; i++) {
            this.$cards[i].style.height = tallest + "px";
        }

        setTimeout(() => {
            this.app.emit("scrollUpdate");
        }, 100);
    }

    initNav() {
        if (this.$el.querySelectorAll(".c-overlap-cards__card__arrow").length) {
            this.$el.querySelectorAll(".c-overlap-cards__card__arrow").forEach((button) => {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    let target = button.getAttribute('data-target');
                    this.app.emit("scrollTo", target);
                })
            });
        }
    }

    initLotties() {
        this.$el.querySelectorAll('[data-lottie-src]').forEach((elt, index) => {
            let url = elt.getAttribute('data-lottie-src');
            elt.setAttribute('data-lottie-index', index);
            if (url) {
                let anim = lottie.loadAnimation({
                    container: elt,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: url,
                });
                this.lotties.push(anim);

                anim.addEventListener('data_ready', () => {
                    setTimeout(() => {
                        this.sameHeight();
                    }, 600);
                });
            }
        });
    }


	destroy() {}
}
  